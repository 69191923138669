/* Navbar.css */

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.navbar {
    background-color: #3498db;
    padding: 1rem;
    color: white;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6px;
}

.navbar-brand a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
}

.navbar-menu {
    display: flex;
    flex-direction: row; /* Ensure horizontal alignment for larger screens */
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navbar-menu li {
    margin: 0 1rem; /* Adjust spacing between menu items */
}

.navbar-menu a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
}

.navbar-menu a:hover {
    background-color: #2980b9;
    border-radius: 5px;
}

.navbar-burger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}



@media (max-width: 767px) {
    .navbar-menu {
        display: none; /* Hide by default on smaller screens */
        position: absolute;
        top: 5rem; /* Adjust based on your design */
        background-color: #3498db;
        width: 100%;
        flex-direction: column;
        text-align: center; /* Center align items */
    }

    .navbar-menu.is-active {
        display: flex; /* Show when active */
    }

    .navbar-menu a {
        display: block;
        padding: 1rem; /* Increase padding for touch areas */
    }

    .navbar-menu a:hover {
        background-color: #2980b9;
        border-radius: 5px;
    }

    .navbar-burger {
        display: flex; /* Show burger icon */
    }
}
